<template>
  <content-holder
    v-if="canViewSplitReceipt"
    :title="'Split Payment'"
    class="p-4 transition-all duration-150 ease-linear"
  >
    <teleport to=".modal">
      <authorize-modal
        title="Payment details"
        v-if="showPaymentDetailsModal"
        @hideModal="showPaymentDetailsModal = false"
      >
        <upload-receipts
          @payment-added="addPayment"
          :batchId="payment.receipt_no"
          :bank="payment.bank"
          :revenueItem="payment.revenue_item"
          :revenueCategory="payment.revenue_category"
        ></upload-receipts>
      </authorize-modal>
    </teleport>

    <div class="bg-blue-400 px-4 py-8 sm:px-10 rounded">
      <div
        class="w-full mx-auto flex flex-col sm:flex sm:flex-row sm:items-center sm:space-x-2"
      >
        <div class="w-full">
          <div class="relative">
            <input
              type="text"
              id="search-form-price"
              @keypress.enter="findPayment"
              :disabled="findingPayment"
              v-model="receiptNumber"
              class="rounded-lg border-transparent flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-gray-600 focus:border-transparent"
              placeholder="Receipt Number / Payment Ref"
            />
          </div>
        </div>
        <button
          type="button"
          @click.prevent="findPayment"
          :disabled="findingPayment"
          class="px-8 py-2 flex items-center justify-center mt-4 sm:mt-0 mx-auto w-full sm:w-auto text-center font-semibold text-white text-sm md:text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-primary focus:outline-none"
        >
          <div v-if="!findingPayment">Search</div>
          <div v-else class="flex items-center space-x-2 py-1 justify-center">
            <i class="fas fa-circle-notch animate-spin text-sm"></i>
            <span class="text-sm">loading...</span>
          </div>
        </button>
        <button
          type="button"
          @click.prevent="resetPage"
          :disabled="!payment.amount"
          class="px-8 py-2 flex items-center disabled:bg-gray-50 justify-center mt-4 sm:mt-0 mx-auto w-full sm:w-auto text-center font-semibold text-white text-sm md:text-lg transition duration-500 ease-in-out transform rounded-lg shadow-xl bg-primary focus:outline-none"
        >
          <div>Clear</div>
        </button>
      </div>
    </div>
    <p class="my-1.5 text-gray-400 text-xs text-center font-medium underline">
      Ensure that the table column fields below match the column fields in the
      excel file to be uploaded.
    </p>

    <div v-if="payment.amount" class="mt-12">
      <div
        class="w-full flex flex-col md:flex-row items-start justify-center md:justify-between"
      >
        <div class="text-center md:text-left">
          <h2 class="text-lg md:text-2xl font-medium leading-6 text-gray-900">
            {{ payment.customer }}
          </h2>
          <h3 class="max-w-2xl mt-1 text-sm md:text-lg text-gray-500">
            {{ payment.revenue_item }}
          </h3>
          <h5 class="max-w-2xl mt-1 text-xs md:text-md text-gray-400">
            {{ formatDate(payment.payment_date) }}
          </h5>
        </div>

        <button
          @click="showPaymentDetailsModal = true"
          class="w-full md:w-auto mt-4 md:mt-0 py-1.5 text-3xl hover:text-2xl md:p-0 rounded-md md:rounded-md focus-within:outline-none"
        >
          <i
            class="fas fa-plus-square hover:shadow shadow-lg md:rounded-md"
          ></i>
        </button>
      </div>

      <div
        class="mt-10 mb-4 text-gray-800 font-medium flex md:flex-row flex-col justify-center md:justify-between items-center bg-gray-50 py-3 md:p-3 md:text-lg rounded"
      >
        <div class="text-center md:text-left">
          Platform Total:: <br class="block md:hidden" />
          <b>{{ payment.amount }}</b>
        </div>
        <hr class="w-full block md:hidden my-1 border-gray-100" />
        <div class="text-center md:text-left">
          Split Total:: <br class="block md:hidden" /><b>{{
            cumulativeTotal
          }}</b>
        </div>
      </div>

      <div
        :style="`height: ${payments.length > 9 ? '55vh' : '100%'} !important`"
        class="overflow-y-scroll"
      >
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table
            class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="p-4 hidden">
                  <div class="flex items-center">
                    <input
                      id="checkbox-all-search"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label for="checkbox-all-search" class="sr-only"
                      >checkbox</label
                    >
                  </div>
                </th>
                <th scope="col" class="px-4 py-3">PAYER</th>
                <th scope="col" class="px-4 py-3">TIN</th>
                <th scope="col" class="px-4 py-3">Gross</th>
                <th scope="col" class="px-4 py-3">Amount</th>
                <th scope="col" class="px-4 py-3">Bank</th>
                <th scope="col" class="px-4 py-3">Date</th>
                <th scope="col" class="px-4 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(payment, index) in payments"
                :key="index"
                class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td class="w-4 p-4 hidden">
                  <div class="flex items-center">
                    <input
                      id="checkbox-table-search-1"
                      type="checkbox"
                      class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded-sm focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:focus:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                    />
                    <label for="checkbox-table-search-1" class="sr-only"
                      >checkbox</label
                    >
                  </div>
                </td>
                <th
                  scope="row"
                  class="px-4 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div
                    class=""
                    style="
                      max-width: 144px !important;
                      word-wrap: break-word !important;
                    "
                  >
                    {{ payment.payer || payment.customer }}
                  </div>
                </th>

                <td class="px-4 py-4">{{ payment.tin }}</td>
                <td class="px-4 py-4">{{ formatAmount(payment.gross) }}</td>
                <td class="px-4 py-4">
                  {{ formatAmount(payment.amount || payment.deduction) }}
                </td>
                <td class="px-4 py-4">
                  <div class="w-36">
                    {{ payment.bank }}
                  </div>
                </td>
                <td class="px-4 py-4">
                  <div class="w-36">
                    {{ formatExcelDate(payment.payment_date || payment.date) }}
                  </div>
                </td>
                <td class="flex items-center space-x-2 px-4 py-4">
                  <button
                    @click.prevent="removePayment(index)"
                    class="font-medium text-red-600 dark:text-blue-500 hover:underline"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            v-if="payments.length < 1"
            class="items-center justify-center w-full flex mt-4"
            @drop.prevent="readUploadedData"
            @dragover.prevent
            @dragenter.prevent
          >
            <label
              for="dropzone-file"
              class="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
            >
              <div class="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  class="w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p class="mb-2 text-sm text-gray-500 dark:text-gray-400">
                  <span class="font-semibold">Click to upload</span> or drag and
                  drop
                </p>
                <p class="text-xs text-gray-500 dark:text-gray-400">
                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                </p>
              </div>
              <input
                @change="readUploadedData"
                id="dropzone-file"
                type="file"
                class="hidden"
                accept=".xls,.xlsx"
              />
            </label>
          </div>
        </div>
      </div>
      <div v-if="payments.length > 0" class="flex sm:justify-end">
        <button
          @click.prevent="resetPageData"
          class="font-medium text-gray-600 dark:text-blue-500 hover:underline sm:mr-6"
        >
          Reset
        </button>
        <button
          type="button"
          @click.prevent="uploadPayments"
          :disabled="uploadingPayment"
          class="px-8 py-2 sm:my-10 mt-4 mb-20 w-full sm:w-auto font-semibold text-white text-md transition duration-500 ease-in-out transform rounded-lg shadow-sm bg-gradient-to-r from-green-600 hover:from-green-600 to-green-600 hover:to-green-600 focus:ring focus:ring-green-600 focus:outline-none"
        >
          <div v-if="!uploadingPayment">Upload</div>
          <div v-else class="flex items-center space-x-4 justify-center">
            <i class="fas fa-circle-notch animate-spin"></i>
            <span>Please wait...</span>
          </div>
        </button>
      </div>
    </div>
  </content-holder>
  <content-holder v-else :title="'Split Receipt'" class="p-4">
    <unauthorized-access></unauthorized-access>
  </content-holder>
</template>

<script setup>
import { unit, mda } from "@/helpers/login_details.js";
import Swal from "sweetalert2";
import { ref, onBeforeMount, computed } from "vue";
import ContentHolder from "@/components/ContentHolder.vue";
import UnauthorizedAccess from "@/components/shared/UnauthorizedAccess.vue";
import AuthorizeModal from "@/components/AuthorizeModal.vue";
import UploadReceipts from "./uploads/UploadReceipts.vue";
import { useStore } from "vuex";
import numeral from "numeral";
import { excelToJson, excelSerialToDayJs } from "@/helpers/json_export.js";
import dayjs from "dayjs";

const canViewSplitReceipt = ref(false);
const showPaymentDetailsModal = ref(false);
const uploadingPayment = ref(false);
const receiptNumber = ref("");
const findingPayment = ref(false);
const payment = ref({});
const payments = ref([]);
// const cumulativeTotal = ref("0.00");
const store = useStore();

const cumulativeTotal = computed(() => {
  let total = 0;
  if (payments.value.length > 0) {
    payments.value.forEach((p) => {
      p.date
        ? (p["payment_date"] = payment.value.payment_date)
        : (p.payment_date = payment.value.payment_date);
      p.bank = payment.value.bank;
      p.revenue_category = payment.value.revenue_category;
      p.revenue_item = payment.value.revenue_item;
      total += parseFloat(p.deduction || p.amount);
    });
  }
  return formatAmount(total);
});

// watch(
//   payments,
//   (val) => {
//     let total = 0;
//     if (val.length > 0) {
//       total = val.reduce((acc, amt) => {
//         return acc + (amt.deduction || amt.amount);
//       }, 0);
//     }
//     cumulativeTotal.value = formatAmount(total);
//   },
//   {
//     deep: true,
//   }
// );

function resetPage() {
  payment.value = {};
  receiptNumber.value = "";
  payments.value = [];
}
function resetPageData() {
  payments.value = [];
}
function formatAmount(amt) {
  return "₦" + numeral(amt).format("0,0.00");
}
function formatDate(dt) {
  return dayjs(dt).format("MMM D, YYYY h:mm A");
}
function formatExcelDate(dt) {
  return !isNaN(dt) ? excelSerialToDayJs(dt) : dt;
}
function addPayment(data) {
  payments.value.push(data);
  showPaymentDetailsModal.value = false;
}
function removePayment(index) {
  payments.value.slice(index, 1);
}
function checkPaymentMatch() {
  let uploadTotal = parseFloat(
    payments.value.reduce((acc, amt) => {
      return acc + (amt.deduction || amt.amount);
    }, 0)
  );
  if (parseFloat(payment.value.strippedAmount.trim()) == uploadTotal)
    return true;

  return false;
}
async function findPayment() {
  try {
    if (receiptNumber.value.length > 0) {
      findingPayment.value = true;
      const paymentDetails = await store.dispatch("getPaymentDetails", {
        receipt_number: receiptNumber.value,
      });

      const { status, msg, data } = paymentDetails;

      if (status == "success") {
        payment.value = data;
      } else {
        Swal.fire({
          title: "Revenue House",
          icon: "error",
          text: msg || "Something went wrong",
        });
      }
    } else {
      Swal.fire({
        title: "Revenue House",
        icon: "error",
        text: "Please enter a receipt number or payment ref.",
      });
    }
  } catch (error) {
    console.error(error);
  } finally {
    findingPayment.value = false;
  }
}
async function uploadPayments() {
  try {
    uploadingPayment.value = true;

    if (payments.value.length > 0) {
      if (checkPaymentMatch()) {
        const result = await store.dispatch("uploadBatchPayment", {
          batch_id: payment.value.receipt_no,
          is_split: true,
          records: payments.value,
        });
        const { status, msg } = result.data;
        if (status == "success") {
          Swal.fire({
            title: "Revenue House",
            icon: "success",
            text: msg,
          });
          resetPage();
        } else {
          Swal.fire({
            title: "Revenue House",
            icon: "error",
            text: msg || "Upload failed, please try again.",
          });
        }
      } else {
        Swal.fire({
          title: "Revenue House",
          icon: "error",
          text: "Upload failed, please check that upload total matches platform total and try again.",
        });
      }
    }
  } catch (error) {
    console.error(error);
  } finally {
    uploadingPayment.value = false;
  }
}
async function readUploadedData(event) {
  try {
    event.preventDefault();
    const file = event.dataTransfer
      ? event.dataTransfer.files[0]
      : event.target.files[0];
    if (!file) return;

    const jsonData = await excelToJson(file);
    payments.value = jsonData.data;
  } catch (error) {
    console.log(error);
  }
}

onBeforeMount(() => {
  if (mda == 1) {
    if (unit.includes("administrator")) {
      canViewSplitReceipt.value = true;
    } else {
      canViewSplitReceipt.value = false;
    }
  }
});
</script>

<style></style>
